<template>
  <div>
    <div class="mid_box">
      <img style="width: 15px" src="../../assets/images/Examine_img/back.png" alt @click="toBack" />
      <span style="margin-right: 15px" @click="toBack">返回</span>
      <el-button
        type="primary"
        class="edit"
        @click="toChoose(false)"
        :disabled="$route.query.fromArchivesManage"
      >
        抽取题目
        <i class="el-icon-circle-plus-outline el-icon--right"></i>
      </el-button>
      <el-button
        type="primary"
        class="edit"
        @click="toChoose(true)"
        :disabled="$route.query.fromArchivesManage"
      >
        增量抽题
        <i class="el-icon-circle-plus-outline el-icon--right"></i>
      </el-button>
      <el-button
        type="primary"
        class="edit"
        @click="addQuestion"
        :disabled="$route.query.fromArchivesManage"
      >
        新增提问
        <i class="el-icon-circle-plus-outline el-icon--right"></i>
      </el-button>
      <div class="toSave1" @click="toSave" v-show="!isCheck">
        <img style="width: 15px" src="../../assets/images/Examine_img/sava.png" alt />
        <span>完成检查</span>
      </div>
      <div class="toSave1" v-if="isCheck" @click="toChange">
        <img style="width: 15px" src="../../assets/images/Examine_img/sava.png" alt />
        <span>已检查</span>
        <span style="color: red; padding: 0 3px">+{{ tableData2.length }}</span>
      </div>
      <div class="toSave1" v-else @click="toChange">
        <img style="width: 15px" src="../../assets/images/Examine_img/sava.png" alt />
        <span>未检查</span>
      </div>
      <span style="margin-top: 3px; margin-left: 10px">
        <img src="../../assets/images/Examine_img/del1.png" alt @click="delItem" />
      </span>
      <el-button style="margin-left: 10px" type="primary" class="edit" @click="getPdf()">打印检查表</el-button>
      <el-button style="margin-left: 10px" type="primary" class="edit" @click="exportExcel2()">导出检查表</el-button>
    </div>
    <el-table
      ref="multipleTable"
      v-if="isCheck"
      :data="tableData1.slice(start, end)"
      tooltip-effect="dark"
      style="width: 100%"
      :key="itemKey"
      highlight-current-row
      id="multipleTable"
      @select="selectChange"
      @select-all="selectAll"
      @row-click="handleRowClick"
      @cell-dblclick="handleCell"
      @selection-change="handleSelectionChange"
      :header-cell-style="{
        background: '#EDF0F7',
        color: '#000000',
      }"
      :height="elementHeight"
      border
    >
      <el-table-column type="selection" width="50"></el-table-column>
      <el-table-column type="index" label="序号" width="50"></el-table-column>
      <el-table-column prop="fileName" label="文件名称" width="195"></el-table-column>
      <el-table-column prop="question" label="问题" width="480"></el-table-column>
      <el-table-column prop="module" label="模块" width="100"></el-table-column>
      <el-table-column prop="auditResult" label="是否符合要求" width="200" v-if="!this.isshowAns">
        <template slot-scope="scope">
          <div class="auditResult_box">
            <div
              @click="changeResult(scope.row, 1, '符合')"
              :class="{ auditactive: scope.row.auditResult === 1 }"
            >符合</div>
            <div
              @click="changeResult(scope.row, 0, '不符合')"
              :class="{ auditactive: scope.row.auditResult === 0 }"
            >不符合</div>
            <!-- <div
              @click="changeResult(scope.row, 2, '纠正')"
              :class="{ auditactive: scope.row.auditResult === 2 }"
            >
              纠正
            </div>-->
            <div
              @click="changeResult(scope.row, 3, '改进')"
              :class="{ auditactive: scope.row.auditResult === 3 }"
            >改进</div>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="查看答案" show-overflow-tooltip v-if="this.isshowAns" prop="answer"></el-table-column>
      <el-table-column label="查看答案" v-else show-overflow-tooltip>
        <template slot-scope="scope">
          <el-button type="primary" class="button" plain @click="isChoose(scope.row)">点击查看</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-table
      v-else
      ref="multipleTable"
      :data="tableData2.slice(start, end)"
      tooltip-effect="dark"
      style="width: 100%"
      :height="elementHeight"
      :key="itemKey"
      id="multipleTable"
      highlight-current-row
      @select="selectChange"
      @select-all="selectAll"
      @row-click="handleRowClick"
      @cell-dblclick="handleCell"
      @selection-change="handleSelectionChange"
      :header-cell-style="{
        background: '#EDF0F7',
        color: '#000000',
      }"
      border
    >
      <el-table-column type="selection" width="50"></el-table-column>
      <el-table-column type="index" label="序号" width="50"></el-table-column>
      <el-table-column prop="fileName" label="文件名称" width="230"></el-table-column>
      <el-table-column prop="question" label="问题" width="480"></el-table-column>
      <el-table-column prop="module" label="模块" width="100"></el-table-column>
      <el-table-column prop="auditResult" v-if="!this.isshowAns" label="是否符合要求" width="250">
        <template slot-scope="scope">
          <div class="auditResult_box">
            <div
              @click="changeResult(scope.row, 1, '符合')"
              :class="{ auditactive: scope.row.auditResult === 1 }"
            >符合</div>
            <div
              @click="changeResult(scope.row, 0, '不符合')"
              :class="{ auditactive: scope.row.auditResult === 0 }"
            >不符合</div>
            <!-- <div
              @click="changeResult(scope.row, 2, '纠正')"
              :class="{ auditactive: scope.row.auditResult === 2 }"
            >
              纠正
            </div>-->
            <div
              @click="changeResult(scope.row, 3, '改进')"
              :class="{ auditactive: scope.row.auditResult === 3 }"
            >改进</div>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="imagesCount"
        label="照片列表"
        v-if="!this.isshowAns"
        align="center"
        width="190px"
      >
        <template slot-scope="scope">
          <div class="img_box" @click="openImg(scope.row)">
            <img src="../../assets/images/Examine_img/add.png" alt />
            <span v-show="scope.row.imagesCount === 0">请添加图片</span>
            <span v-show="scope.row.imagesCount > 0" style="margin-right: 50%">{{ srcList.length }}</span>
            <img src="../../assets/images/Examine_img/picture.png" alt />
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="lawAsk"
        label="审核意见（双击列表）"
        show-overflow-tooltip
        v-if="!this.isshowAns"
      >
        <template slot-scope="scope">
          <input
            v-if="AreaAuditId === scope.row.id && columnID === scope.column.id && status != 2"
            type="text"
            @focus="compare(scope.row.lawAsk)"
            @blur="Updata(scope.row, scope.row.lawAsk)"
            v-model="scope.row.lawAsk"
            :disabled="$route.query.fromArchivesManage"
          />
          <span v-else>{{ scope.row.lawAsk }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="answer" label="答案" v-if="this.isshowAns" show-overflow-tooltip></el-table-column>
      <el-table-column prop="answer" label="查看答案" v-else show-overflow-tooltip>
        <template slot-scope="scope">
          <el-button type="primary" class="button" plain @click="isChoose(scope.row)">点击查看</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page.sync="currentPage1"
      :page-sizes="[10, 20, 50]"
      :page-size="curSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="isCheck ? tableData1.length : tableData2.length"
    ></el-pagination>
    <el-dialog title="抽取题目" :visible.sync="dialogVisible" width="45%">
      <div style="margin-bottom: 20px" class="text">
        <div style="display: flex">
          <span style="margin: 10px; margin-left: 0.8%">抽取题目:&ensp;</span>
        </div>
        <el-table
          :header-cell-style="{
            background: '#EDF0F7',
            color: '#000000',
          }"
          :data="titlelist"
          style="width: 100%"
          @selection-change="selectionDraw"
        >
          <el-table-column prop="module" label="模块名称"></el-table-column>
          <el-table-column prop="questionCount" label="抽取数量"></el-table-column>
          <el-table-column type="selection" width="100"></el-table-column>
        </el-table>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="drawQuestions">确定抽题</el-button>
        <el-button @click="dialogVisible = false">取消</el-button>
      </span>
    </el-dialog>
    <el-dialog title="新增提问" :visible.sync="dialogVisible1" width="38%">
      <div style="margin-bottom: 20px" class="text">
        <div style="display: flex">
          <div style="margin: 20px; width: 60px">问题描述</div>
          <el-input type="textarea" :rows="5" placeholder="请输入内容" v-model="PostData.question"></el-input>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="toAddASK">确定提问</el-button>
        <el-button @click="dialogVisible1 = false">取消</el-button>
      </span>
    </el-dialog>
    <el-dialog title="查看/添加图片" :visible.sync="dialogVisibleimg" width="40%">
      <div v-show="!$route.query.fromArchivesManage && status != 2">上传图片</div>
      <el-upload
        action="https://jsonplaceholder.typicode.com/posts/"
        list-type="picture-card"
        ref="upload"
        :on-preview="handlePictureCardPreview"
        :http-request="uploadFile"
        accept=".JPG, .PNG, .JPEG, .jpg, .png, .jpeg"
        :headers="token"
        v-show="!$route.query.fromArchivesManage && status != 2"
      >
        <i class="el-icon-plus"></i>
      </el-upload>
      <div>已上传图片</div>
      <span class="ImgDel" v-for="(item, index) in srcList" :key="item">
        <el-image
          style="width: 100px; height: 100px"
          :src="item"
          :preview-src-list="srcList"
          @mouseover="overimg(index)"
        ></el-image>

        <i class="el-icon-delete-solid" v-show="index === Curindex" @click="delImg(item)"></i>
      </span>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="toPostImg">确 定</el-button>
        <el-button @click="dialogVisibleimg = false">取 消</el-button>
      </span>
    </el-dialog>
    <el-dialog title="查看答案" :visible.sync="dialogVisibleAsk" width="40%">
      <div style="font-size: 18px">答案：{{ answer }}</div>
      <div style="font-size: 18px">图片：</div>
      <span class="ImgDel" v-for="(item, index) in srcList" :key="item">
        <el-image
          style="width: 100px; height: 100px"
          :src="item"
          :preview-src-list="srcList"
          @mouseover="overimg(index)"
        ></el-image>
      </span>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="toPostImg">确 定</el-button>
        <el-button @click="dialogVisibleAsk = false">取 消</el-button>
      </span>
    </el-dialog>
    <el-dialog title="审核结果" :visible.sync="dialogVisibleresult" width="35%">
      <el-form label-width="80px">
        <el-form-item label="审核结果:">
          <div style="display: flex; align-items: center">
            <span>{{ this.state }}</span>
            <div v-show="state != '符合'" style="margin-left: 20px">
              <span>扣</span>
              <el-input
                class="editNum"
                style="width: 50px; margin: 0 5px"
                v-model="curRow.violatedTerms"
                type="number"
              ></el-input>
              <span>分</span>
            </div>
          </div>
          <!-- <el-radio
            v-model="curRow.unqualityType"
            v-show="state === '不符合'"
            label="轻微不符合"
            >轻微不符合</el-radio
          >
          <el-radio
            v-model="curRow.unqualityType"
            v-show="state === '不符合'"
            label="严重不符合"
            >严重不符合</el-radio
          >-->
        </el-form-item>
        <el-form-item label="审核意见:">
          <el-input v-model="curRow.lawAsk" type="textarea" :rows="2"></el-input>
        </el-form-item>
        <el-form-item label="上传照片:">
          <el-upload
            action=" https://jsonplaceholder.typicode.com/posts/"
            list-type="picture-card"
            ref="upload"
            :on-preview="handlePictureCardPreview"
            :http-request="uploadFile"
            accept=".JPG, .PNG, .JPEG, .jpg, .png, .jpeg"
            :headers="token"
          >
            <i class="el-icon-plus"></i>
          </el-upload>
          <div>已上传图片</div>
          <span class="ImgDel" v-for="(item, index) in srcList" :key="item">
            <el-image
              style="width: 100px; height: 100px"
              :src="item"
              :preview-src-list="srcList"
              @mouseover="overimg(index)"
            ></el-image>
            <i class="el-icon-delete-solid" v-show="index === Curindex" @click="delImg(item)"></i>
          </span>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisibleresult = false">取消</el-button>
        <el-button type="primary" @click="toSure">确认</el-button>
      </span>
    </el-dialog>
    <el-table
      :data="isCheck ? tableData1 : tableData2"
      style="width: 100%; position: absolute; left: -100%; z-index: -999"
      id="pdfDom"
    >
      <!-- <el-table-column type="selection" width="50"></el-table-column> -->
      <el-table-column :label="'公司:' + companyName">
        <el-table-column type="index" label="序号" width="50"></el-table-column>
        <el-table-column prop="fileName" label="文件名称" width="230"></el-table-column>
        <el-table-column prop="question" label="问题" width="480"></el-table-column>
        <el-table-column prop="module" label="模块" width="100"></el-table-column>
        <el-table-column prop="auditResult" label="是否符合要求" width="250">
          <template slot-scope="scope">
            <div class="auditResult_box">
              <div :class="{ auditactive: scope.row.auditResult === 1 }" v-if="scope.row.auditResult === 1">符合</div>
              <div :class="{ auditactive: scope.row.auditResult === 0 }" v-else-if="scope.row.auditResult === 0">不符合</div>
              <div :class="{ auditactive: scope.row.auditResult === 2 }" v-else-if="scope.row.auditResult === 2">纠正</div>
              <div :class="{ auditactive: scope.row.auditResult === 3 }" v-else>改进</div>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="imagesCount"
          label="照片列表"
          align="center"
          width="190px
        "
          v-if="!this.isshowAns"
        >
          <template slot-scope="scope">
            <div class="img_box">
              <img src="../../assets/images/Examine_img/add.png" alt />
              <span v-show="scope.row.imagesCount === 0">请添加图片</span>
              <span
                v-show="scope.row.imagesCount > 0"
                style="margin-right: 50%"
              >{{ srcList.length }}</span>
              <img src="../../assets/images/Examine_img/picture.png" alt />
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="answer" label="答案" v-if="this.isshowAns" width="300"></el-table-column>

        <el-table-column prop="answer" label="答案" v-else width="300"></el-table-column>
        <el-table-column prop="lawAsk" label="审核意见（双击列表）" width="300">
          <template slot-scope="scope">
            <span>{{ scope.row.lawAsk }}</span>
          </template>
        </el-table-column>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { deleteMethod, get, post, put, baseURL } from '../../api/http'
import { exportExcel } from '../../modules/ExportExcel'
import { compressImage } from '../../modules/CompressImage'
import imgTypeChange from '../../modules/imgChange'
import { excelAutoWidth } from '../../modules/Utils'

export default {
  data() {
    return {
      token: {
        Authorization:
          'Bearer ' + JSON.parse(window.sessionStorage.getItem('token')),
      },
      htmlTitle: '考核细则检查表',
      elementHeight: 0,
      BUrl: baseURL[process.env.NODE_ENV + ''],
      dialogVisible: false,
      dialogVisible1: false,
      dialogVisibleimg: false,
      dialogVisiblebig: false,
      dialogVisibleAsk: false,
      dialogVisibleresult: false,
      tableData: [],
      tableData1: [],
      tableData2: [],
      state: '',
      Cid: null,
      excelAutoWidth: excelAutoWidth,
      isCheck: true,
      itemKey: '',
      textarea: '',
      titlelist: [],
      imgList: [],
      srcList: [],
      curRow: {},
      curNum: '',
      value: '',
      currentPage: 0,
      company: Number(this.$route.query.CpyID),
      number: '',
      url: '',
      imgSrc: '',
      showImg: false,
      Curindex: '',
      AreaAuditId: '',
      columnID: '',
      isshowAns: false,
      option2: '',
      curlawask: '',
      isSave: true,
      start: 0,
      end: 10,
      currentPage1: 1,
      curSize: 10,
      option1: '',
      answer: '',
      PostData: {
        fileAuditId: this.$route.query.Id,
        questionId: 0,
        question: '',
        answer: '',
        lawAsk: '',
        imagesCount: 0,
      },
      isAddDraw: false,
      companyName: '',
      status:this.$route.query.status,
    }
  },
  mounted() {
    this.getData()
    this.getElementHeight()
    this.getCompany()
  },
  methods: {
    getElementHeight() {
      this.$nextTick(() => {
        this.elementHeight =
          window.innerHeight -
          (document.getElementById('multipleTable').offsetTop + 60 + 52)
      })
    },
    getCompany() {
      this.$store.state.companyUserList.forEach((item) => {
        if (item.id == this.company) {
          this.companyName = item.companyName
        }
      })
    },
    getData() {
      get(
        '/api/FileAuditList/GetByFileAuditId?FileAuditId=' +
          this.$route.query.Id
      ).then((res) => {
        this.tableData = res.data
        this.tableData1 = this.tableData.filter((item) => {
          return item.auditResult === null
        })
        this.tableData2 = this.tableData.filter((item) => {
          return item.auditResult !== null
        })
      })
    },
    toChoose(isAddDraw) {
      this.isAddDraw = isAddDraw
      if (this.tableData2.length != 0 && !isAddDraw) {
        this.$message({
          type: 'warning',
          message: '已有题目审核，不能重新抽题',
        })
        return
      }
      get(
        '/api/NewExamineRules/GetTitleList?Name=' +
          this.$route.query.typeBtnIndex +
          '&FileAuditId=' +
          this.$route.query.Id
      ).then((resp) => {
        if (resp.code == 200) {
          this.titlelist = resp.data
        }
      })
      this.dialogVisible = true
    },
    addQuestion(){
      if(this.status == 2){
        this.$message({
          type: 'warning',
          message: '已经审核完成，不能添加题目',
        })
        return
      }
      this.dialogVisible1 = true;
    },
    async toSave() {
      if (this.$route.query.fromArchivesManage) return
      if (this.tableData1.length != 0) {
        this.$message({
          type: 'warning',
          message: '未完成审核',
        })
        return
      }
      var item = JSON.parse(sessionStorage.getItem('Fitem'))
      if (item.status == 2) {
        this.$message({
          type: 'success',
          message: '已经审核完成，请勿重复完成',
        })
      } else {
        item.status = 2
        this.status = 2;
        this.$router.push({
          query:{...this.$route.query,status:this.status}
        })
        sessionStorage.setItem('Fitem', JSON.stringify(item))
        put('/api/NewExamineRules', item).then((res) => {
          this.$message({
            type: 'success',
            message: res.message,
          })
        })
      }
    },
    toBack() {
      this.$router.go(-1)
    },
    selectionDraw(e) {
      console.log(e)
      this.examineExtract = e
    },
    drawQuestions() {
      var url = ''
      var url1 = '/api/NewExamineRules/InsertExtract' //未抽题或增量抽题
      var url2 = '/api/NewExamineRules/AgainExtract' //重新
      if (this.isAddDraw) {
        url = url1 + '?FileAuditId=' + this.$route.query.Id + '&'
      } else {
        url =
          this.tableData1.length == 0 && this.tableData2.length == 0
            ? url1 + '?'
            : url2 + '?'
      }
      var lstExamineExtract = []
      this.examineExtract.map((i) => {
        lstExamineExtract.push({ module: i.module })
      })
      post(url + 'Name=' + this.$route.query.typeBtnIndex, lstExamineExtract)
        .then((resp) => {
          if (resp.code == 200) {
            this.$message({
              type: 'success',
              message: '抽题成功',
            })
            this.dialogVisible = false
            this.getData()
          } else {
            this.$message.error(resp.message)
          }
        })
        .catch(() => {
          this.$message.error('抽题失败')
        })
    },
    overimg(index) {
      this.Curindex = index
    },
    openImg(e) {
      this.Cid = e.id
      this.dialogVisibleimg = true
      this.getimg()
    },
    delImg(item) {
      this.imgList.forEach((e) => {
        if (e.picNameAndAddr === item) {
          this.$confirm('此操作将永久删除该图片', '是否继续?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          })
            .then(() => {
              deleteMethod('/api/FileAuditListImage?Id=' + e.id).then(() => {
                this.getimg()
              })
              this.$message({
                type: 'success',
                message: '删除成功!',
              })
            })
            .catch(() => {
              this.$message({
                type: 'info',
                message: '已取消删除',
              })
            })
        }
      })
    },
    changeResult(row, num, state) {
      if(this.status == 2){
        this.$message({
          type: 'warning',
          message: '审核完成，无法修改',
        })
        return
      }
      if (this.$route.query.fromArchivesManage) return
      if (num >= 0) {
        console.log(row)
        this.state = state
        this.Cid = row.id
        this.curRow = row
        this.curNum = num
        this.dialogVisibleresult = true
        this.getimg()
        return
      }
      put('/api/FileAuditList', row).then((res) => {
        if (res.code != '200') {
          this.$message({
            type: 'error',
            message: res.message,
          })
          row.auditResult = null
          return
        }
        row.auditResult = num
        this.$message({
          type: 'success',
          message: res.code == 200 ? '保存成功' : res.message,
        })
        for (var i = 0; i < this.tableData1.length; i++) {
          if (this.tableData1[i].id === row.id) {
            this.tableData1.splice(i, 1)
          }
        }
        for (var k = 0; k < this.tableData2.length; k++) {
          if (this.tableData2[k].id === row.id) {
            return
          }
        }
        this.tableData2.push(row)
      })
    },

    toSure() {
      this.curRow.auditResult = this.curNum
      put('/api/FileAuditList', this.curRow).then((res) => {
        if (res.code != 200) {
          this.curRow.auditResult = null
        }
        this.$message({
          type: 'success',
          message: res.code == 200 ? '保存成功' : res.message,
        })
        for (var i = 0; i < this.tableData1.length; i++) {
          if (this.tableData1[i].id === this.curRow.id) {
            this.tableData1.splice(i, 1)
          }
        }
        for (var k = 0; k < this.tableData2.length; k++) {
          if (this.tableData2[k].id === this.curRow.id) {
            return
          }
        }
        //this.$refs.upload.clearFiles();
        this.tableData2.push(this.curRow)
        this.$refs.upload.clearFiles()
      })
      this.dialogVisibleresult = false
      this.srcList = []
    },
    toChange() {
      this.isCheck = !this.isCheck
      this.start = 0
      this.end = 10
      this.currentPage1 = 1
    },
    compare(e) {
      this.curlawask = e
    },
    Updata(row, e) {
      this.columnID = ''
      if (this.curlawask === e) return
      put('/api/FileAuditList', row).then((res) => {
        this.$message({
          type: 'success',
          message: res.code == 200 ? '更改成功' : res.message,
        })
        //this.getData()
      })
    },
    handleSizeChange(e) {
      this.curSize = e
      this.start = (this.currentPage1 - 1) * this.curSize
      this.end = this.start + this.curSize
    },
    handleCurrentChange(e) {
      this.start = (e - 1) * this.curSize
      this.end = this.start + this.curSize
    },
    handleClose() {},
    exportExcel2() {
      this.start = 0
      this.isshowAns = true
      this.end = this.tableData.length
      this.tableData1 = this.tableData
      this.tableData2 = this.tableData
      this.$nextTick(() => {
        exportExcel('#pdfDom', '考核细则.xlsx')
        this.start = 0
        this.end = 10
        this.isshowAns = false
        this.getData()
      })
    },
    handlePictureCardPreview() {
      this.dialogVisiblebig = true
    },
    async uploadFile(e) {
      if (e.file) {
        let file = await imgTypeChange(e.file)
        let newfile = await compressImage(file)
        if (newfile.size > 10000) {
          newfile = await compressImage(newfile)
        }
        this.uploadFileReq(newfile)
      }
    },
    uploadFileReq(file) {
      var formData = new FormData()
      formData.append('files', file)
      post('/api/UpLoadFiles/UploadImg', formData)
        .then((res) => {
          this.$refs.upload.clearFiles()
          if (res.code == 200) {
            var data = {
              fileAuditListtId: this.Cid,
              ImageRemark: '',
              ImageAddress: this.BUrl + '/uploads/' + res.value,
              saveUserId: JSON.parse(sessionStorage.getItem('userInfo')).id,
            }
            post('/api/FileAuditListImage', {
              data: JSON.stringify([data]),
            }).then(() => {
              this.$refs.upload.clearFiles()
              this.getimg()
            })
          } else {
            this.$message.error(res.message)
          }
        })
        .catch(() => {
          this.$refs.upload.clearFiles()
          this.$message.error('上传失败')
        })
    },
    getimg() {
      this.srcList = []
      get('/api/FileAuditListImage/' + this.Cid).then((res) => {
        this.imgList = res.value
        res.value.forEach((element) => {
          this.srcList.push(element.picNameAndAddr)
        })
      })
    },
    toAddASK() {
      this.dialogVisible1 = false
      post('/api/FileAuditList?AuditType=安全考核', this.PostData).then(() => {
        this.getData()
      })
    },
    toPostImg() {
      this.dialogVisibleimg = false
      this.$refs.upload.clearFiles()
      this.getData()
      //this.$refs.upload.clearFiles();
    },
    handleCell(row, column) {
      this.columnID = column.id
    },
    togetArea(e) {
      get('/api/Area/GetByCompId?CompId=' + e).then((res) => {
        this.option2 = res.data
      })
    },
    delItem() {
      if(this.status == 2){
        this.$message.warning('审核完成，不能删除')
        return
      }
      if (this.$route.query.fromArchivesManage) return
      this.$confirm('此操作将永久删除该题目', '是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          deleteMethod('/api/FileAuditList?Id=' + this.AreaAuditId).then(() => {
            this.getData()
          })
          this.$message({
            type: 'success',
            message: '删除成功!',
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
          })
        })
    },
    handleSelectionChange(value) {
      this.handleSelectionList = value
    },

    selectChange(selection) {
      if (selection.length > 1) {
        const del_row = selection.shift()
        this.$refs.multipleTable.toggleRowSelection(del_row, false)
      }
    },
    handleRowClick(row) {
      this.AreaAuditId = row.id
      this.$refs.multipleTable.toggleRowSelection(row)
      this.selectChange(this.handleSelectionList)
    },
    selectAll(selection) {
      if (selection.length > 1) {
        selection.length = 1
      }
    },
    isChoose(e) {
      this.srcList = []
      this.answer = e.answer
      this.dialogVisibleAsk = true
      if (e.questionImageAddr) {
        var imgdata = e.questionImageAddr.split(",");
        imgdata.forEach((e) => {
          this.srcList.push(this.BUrl + "/uploads/" + e);
        });
      }
      // if (e.imagesCount.length != 0) {
      //   this.Cid = e.id
      //   this.getimg()
      // }
    },
  },
}
</script>

<style lang="less" scoped>
@import '../../assets/style/Examine_CSS.css';
/deep/.editNum {
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
  input[type='number'] {
    -moz-appearance: textfield;
  }
  .el-input__inner {
    padding: 0 !important;
    text-align: center;
    color: red;
  }
}
</style>
